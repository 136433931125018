<script>
import { mixins, Bar } from 'vue-chartjs'

export default {
  extends: Bar,

  data() {
    return {
      options: {
        label: false,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true
              },
              gridLines: {
                display: true
              }
            }
          ],

          xAxes: [
            {
              gridLines: {
                display: true
              }
            }
          ]
        },
        legend: {
          display: false
        },
        tooltips: {
          backgroundColor: '#fff',
          titleFontSize: 16,
          titleFontColor: '#45414b',
          titleMarginBottom: 10,
          bodyFontColor: '#45414b',
          borderColor: '#d3d2d2',
          borderWidth: 1,
          bodyFontSize: 14,
          bodySpacing: 6,
          xPadding: 12,
          yPadding: 12,
          multiKeyBackground: '#d3d2d2'
        },
        responsive: true,
        maintainAspectRatio: false
      }
    }
  },

  mixins: [mixins.reactiveProp],

  props: {
    chartData: {
      type: Object,
      default() {
        return {
          datasets: [],
          labels: []
        }
      }
    }
  },

  mounted() {
    this.renderChart(this.chartData, this.options)
  }
}
</script>
